// components/ConfigureAmplify.tsx
"use client";

import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/data";
import outputs from "@/amplify_outputs.json";
import { Schema } from "@/amplify/data/resource";
Amplify.configure(outputs, {
  ssr: true
});
export default function ConfigureAmplifyClientSide() {
  return null;
}
export const dataClient = generateClient<Schema>();