'use client';

import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';
const secondaryColor = '#33B3CC'; // Analogous to primary color
const accentColor = '#CC3366'; // A vibrant pink
const primaryColor = '#33CC99'; // A vibrant green

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
  }
}
let theme = createTheme({
  typography: {
    fontFamily: 'var(--font-roboto)',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '-0.02em'
    },
    h4: {
      fontWeight: 600,
      letterSpacing: '-0.02em'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7
    },
    button: {
      fontWeight: 500,
      letterSpacing: '0.02em'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: primaryColor,
      light: alpha(primaryColor, 0.7),
      dark: primaryColor,
      contrastText: '#ffffff'
    },
    secondary: {
      main: secondaryColor,
      light: alpha(secondaryColor, 0.7),
      dark: secondaryColor,
      contrastText: '#ffffff'
    },
    accent: {
      main: accentColor,
      light: alpha(accentColor, 0.7),
      dark: accentColor,
      contrastText: '#ffffff'
    },
    background: {
      default: '#0A0A0A',
      paper: '#1A1A1A'
    },
    text: {
      primary: '#F7FAFC',
      secondary: '#A0AEC0'
    }
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '0.75rem 1.5rem',
          boxShadow: 'none',
          WebkitTransition: 'all 0.2s ease-in-out',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            boxShadow: 'none',
            WebkitTransform: 'translateY(-1px)',
            transform: 'translateY(-1px)'
          }
        },
        contained: {
          '&:hover': {
            transform: 'translateY(-1px)',
            transition: 'transform 0.2s ease-in-out'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*, *::before, *::after': {
          textRendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: alpha('#fff', 0.15)
            },
            '&:hover fieldset': {
              borderColor: alpha('#fff', 0.25)
            }
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: alpha('#1A1A1A', 0.8),
          backdropFilter: 'blur(10px)'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          isolation: 'isolate'
        }
      },
      defaultProps: {
        MenuProps: {
          slotProps: {
            paper: {
              sx: {
                backgroundImage: 'none',
                backgroundColor: 'rgba(26,26,26,0.95) !important',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255,255,255,0.1)',
                mt: 1
              }
            }
          },
          TransitionProps: {
            timeout: {
              enter: 0,
              exit: 0
            }
          }
        }
      }
    }
  }
});
theme = responsiveFontSizes(theme);
export default theme;