import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/components/ClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/components/ConfigureAmplify.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@aws-amplify/ui-react/dist/styles.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/app/globals.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2809324964/src/amplify-nextjs-mushmyco-app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\",\"adjustFontFallback\":false}],\"variableName\":\"roboto\"}");
